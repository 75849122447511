import Collection from "components/Collection";
import Layout from "components/Layout";
import SEO from "components/Seo";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

function PressPage() {
  const data = useStaticQuery(graphql`
    query PressQuery {
      allContentfulPagePress {
        edges {
          node {
            pageTitle
            heroSectionHeading
            pressEntries {
              heading
              description
              url
            }
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulPagePress.edges[0].node;
  return (
    <Layout>
      <SEO
        // metaDescription={dataNode.pageDescription}
        title={dataNode.pageTitle}
      />
      <Collection data={dataNode} />
    </Layout>
  );
}

export default PressPage;
